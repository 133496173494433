import { useSelector } from 'react-redux'
import {
    getCredit,
    getIsLogged,
    getUserId,
    getUserName,
    getUserNameSlug,
} from '../../../store/login/reducers'
import React from 'react'
import DisplayPrice from '../../product/DisplayPrice'
import LocalizedLink from '../../global/LocalizedLink'
import Button from '../../global/Button'
import { ProfileLogo } from '../../global/ProfileLogo'
import { QrDownloadModal } from '../../global/QrDownlaodModal'
import { ShareLinkModal } from '../../global/ShareLinkModal'
import { routeGenerator } from '../../global/RouteGenerator'
import { useIntl } from 'react-intl'

const ProfileSumarry = (props) => {

    const isLogged = useSelector(state => getIsLogged(state))
    const username = useSelector(state => getUserName(state))
    const userNameSlug = useSelector(state => getUserNameSlug(state))
    const userId = useSelector(state => getUserId(state))
    const credit = useSelector(state => getCredit(state))

    const intl = useIntl()

    const publicProfileLink = routeGenerator(intl, 'ROUTE_PUBLIC_USER_PROFILE', { userNameSlug: userNameSlug })

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row justify-content-between">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <ProfileLogo userName={username} />
                                            <div className="col-8 col-md-6 col-lx-4 mt-2 mb-2">
                                                <h4>{username}</h4>
                                                <div className="color-thunder">
                                                    Credit <DisplayPrice price={credit} showZero={true}></DisplayPrice>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="checkout-summary col-12 col-md-6 col-lx-4" style={{ display: 'flex', gap: '8px' }}>
                                        <ShareLinkModal title={'Share my public profile'} message={'This is my public profile in Cards and Collections'} url={publicProfileLink} />
                                        <QrDownloadModal title={'Qr of my public profile link'} url={publicProfileLink} />{' '}
                                        <LocalizedLink routeId={'ROUTE_PUBLIC_USER_PROFILE'} params={{ userNameSlug: userNameSlug }}>
                                            <Button icon="thin fa-arrow-right ps-2" customclassName="mb-1" iconPosition="end">
                                                My public profile
                                            </Button>
                                        </LocalizedLink>
                                        {/*<Button icon ="trash-alt" customclassName="color-thunder bg-color-iron" >*/}
                                        {/*    <Translation id='delete_account' defaultMessage='Delete account' />*/}
                                        {/*</Button>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProfileSumarry
