import * as api from '../api'
import { push } from 'react-router-redux'
import { getCredit, getIsLogged, getToken, getUserId } from './reducers'
import { websocketInstance } from '../../index'

export const SIMPLE_LOGIN_REQUEST = 'SIMPLE_LOGIN_REQUEST'
export const SIMPLE_LOGIN_SUCCESS = 'SIMPLE_LOGIN_SUCCESS'

export const SIMPLE_IMPERSONATE_REQUEST = 'SIMPLE_IMPERSONATE_REQUEST'
export const SIMPLE_IMPERSONATE_SUCCESS = 'SIMPLE_IMPERSONATE_SUCCESS'
export const SIMPLE_IMPERSONATE_FAILURE = 'SIMPLE_IMPERSONATE_FAILURE'


export const login = (username, password) => async (dispatch, getState) => {
    dispatch({ type: 'LOGIN_REQUEST', username, password })

    // hardcodeo provisional

    // let validUsers = [
    //     {userName:'joaquin',password:'1234'}
    // ]
    // let validLogin = false;
    // validUsers.map(user => {
    //     if(username === user.userName && password === user.password) validLogin = true;
    //     return validLogin
    // })
    // if(validLogin){
    //     dispatch({ type: 'LOGIN_SUCCESS', userName:validUsers[0].userName,response: validUsers[0] })
    // }

    // fin hardcodeo

    return api.login(username, password).then(
        (response) => {
            dispatch({ type: 'LOGIN_SUCCESS', token: response.data })
        },
        (error) => {
            dispatch({ type: 'LOGIN_FAILURE', response: error.response, statusText: error.response.data.err })
            // api.errorHandler(dispatch, error.response)
        }
    )
}

export const logout = () => (dispatch, getState) => {
    dispatch({ type: 'LOGOUT' })
    dispatch(push(`/`))

    websocketInstance.disconnect()

    // const locale = getLanguage(getState()) === 'en' ? '' : getLanguage(getState())

    // return api.logoutOnApi(getUserId(getState())).then(
    //     (response) => {
    //         dispatch({ type: 'LOGOUT_SUCCESS', response: response.data })
    //         dispatch(push(`/${locale}`))
    //     },
    //     (error) => {
    //         dispatch({ type: 'LOGOUT_FAILURE', response: error.response })
    //         api.errorHandler(dispatch, error.response)
    //     }
    // )
}

export const loginRequired =
    (callback = null) =>
        (dispatch, getState) => {
            dispatch({
                type: 'LOGIN_REQUIRED',
                // callback: callback ? callback : () => { },
                callback: () => {
                },
                payload: { statusText: 'LOGIN_REQUIRED' }
            })
        }

export const recoverPassword = email => (dispatch) => {
    dispatch({ type: 'RECOVER_PASSWORD_REQUEST', email })

    return api
        .recoverPassword(email)
        .then((response) => {
            dispatch({ type: 'RECOVER_PASSWORD_SUCCESS', statusText: 'RESEND_EMAIL_EVENT', status: response.status })
        })
        .catch((error) => {
            dispatch({
                type: 'RECOVER_PASSWORD_FAILURE',
                statusText: 'RESEND_EMAIL_EVENT',
                status: error.response.status
            })
        })
}

export const resetPassword = (formData, route) => dispatch => {
    dispatch({ type: 'RESET_PASSWORD_REQUEST' })

    api.resetPassword(formData)
        .then((response) => {
            dispatch({ type: 'RESET_PASSWORD_SUCCESS', formData })
            dispatch(push(route))
        })
        .catch((error) => {
            dispatch({ type: 'RESET_PASSWORD_FAILURE', statusText: error.response.data, status: error.response.status })
        })
}

export const createAccount = (formData, route) => (dispatch) => {
    dispatch({ type: 'CREATE_ACCOUNT_REQUEST' })

    api.createAccount(formData)
        .then((response) => {
            dispatch({ type: 'CREATE_ACCOUNT_SUCCESS', name: formData.name })
            dispatch(push(route))
        })
        .catch((error) => {
            // console.log('error')
            dispatch({
                type: 'CREATE_ACCOUNT_FAILURE',
                statusText: error.response.data,
                status: error.response.status,
                errors: error.response.data.errors
            })
        })
}

export const verifyAccount = (urlVerification) => async (dispatch, getState) => {
    dispatch({ type: 'VERIFY_ACCOUNT_REQUEST', urlVerification })

    const currentLoggedUserId = getUserId(getState());

    return api.verifyAccount(urlVerification, currentLoggedUserId).then(
        (response) => {
            dispatch({ type: 'VERIFY_ACCOUNT_SUCCESS' })
            dispatch({ type: 'LOGIN_SUCCESS', token: response.data })
        },
        (error) => {
            dispatch(push(`/`))
            dispatch({ type: 'VERIFY_ACCOUNT_FAILURE', response: error.response, statusText: error.response?.data?.err })
        }
    )
}

export const resendVerificationEmail = (name) => (dispatch) => {
    dispatch({ type: 'RESEND_VERIFICATION_EMAIL_REQUEST' })

    api.resendVerificationEmail(name)
        .then((response) => {
            dispatch({ type: 'RESEND_VERIFICATION_EMAIL_SUCCESS' })
        })
        .catch((error) => {
            dispatch({ type: 'RESEND_VERIFICATION_EMAIL_FAILURE' })
        })
}

// Personal info

export const updatePersonalInformation = (formData) => (dispatch, getState) => {
    dispatch({ type: 'UPDATE_PERSONAL_INFORMATION_REQUEST' })

    api.updatePersonalInformation(getToken(getState()), formData).then((response) => {
        dispatch({ type: 'UPDATE_PERSONAL_INFORMATION_SUCCESS', formData })
    }).catch((error) => {
        dispatch({
            type: 'UPDATE_PERSONAL_INFORMATION_FAILURE',
            statusText: error.response.data,
            status: error.response.status
        })
    })
}

export const changePassword = (formData) => (dispatch, getState) => {
    dispatch({ type: 'CHANGE_PASSWORD_REQUEST' })

    api.changePassword(getToken(getState()), formData).then((response) => {
        dispatch({ type: 'CHANGE_PASSWORD_SUCCESS' })
    }).catch((error) => {
        dispatch({ type: 'CHANGE_PASSWORD_FAILURE', statusText: 'WRONG_CREDENTIALS', status: 401 })
    })
}

export const updateShippingInformation = (formData) => (dispatch, getState) => {
    dispatch({ type: 'UPDATE_SHIPPING_INFORMATION_REQUEST' })

    api.updateShippingInformation(getToken(getState()), formData).then((response) => {

        dispatch({ type: 'UPDATE_SHIPPING_INFORMATION_SUCCESS', shippingAddress: response.data })
    }).catch((error) => {
        let errors = null
        if (error.response && error.response.status === 422) {
            errors = error.response.data.errors
        }
        dispatch({
            type: 'UPDATE_SHIPPING_INFORMATION_FAILURE',
            statusText: error.response.data.message,
            status: error.response.status,
            errors
        })
    })
}

export const updateFinancialInformation = (formData) => (dispatch, getState) => {
    dispatch({ type: 'UPDATE_FINANCIAL_INFORMATION_REQUEST' })
    api.updateFinancialInformation(getToken(getState()), formData).then((response) => {
        dispatch({ type: 'UPDATE_FINANCIAL_INFORMATION_SUCCESS', formData })
        dispatch(getBankValidationInfo())
    }).catch((error) => {
        let errors = null
        if (error.response && error.response.status === 422) {
            errors = error.response.data.errors
        }
        dispatch({
            type: 'UPDATE_FINANCIAL_INFORMATION_FAILURE',
            statusText: 'INVALID_DATA',
            status: error.response.status,
            errors
        })
    })
}

export const updateBusinessConfig = (formData) => (dispatch, getState) => {
    dispatch({ type: 'UPDATE_BUSINESS_CONFIG_REQUEST' })

    api.updateBusinessConfig(getToken(getState()), formData).then((response) => {
        dispatch({ type: 'UPDATE_BUSINESS_CONFIG_SUCCESS', formData })
    }).catch((error) => {
        dispatch({
            type: 'UPDATE_BUSINESS_CONFIG_FAILURE',
            statusText: error.response.data,
            status: error.response.status
        })
    })
}

export const setBuyCredit = (buyCredit) => (dispatch, getState) => {
    dispatch({ type: 'SET_BUY_CREDIT_REQUEST' })

    api.setBuyCredit(getToken(getState()), buyCredit)
        .then((response) => {
            dispatch({ type: 'SET_BUY_CREDIT_SUCCESS', buyCredit })
        })
        .catch((error) => {
            let errors = null
            if (error.response && error.response.status === 422) {
                errors = error.response.data.errors
            }
            dispatch({ type: 'SET_BUY_CREDIT_FAILURE', errors })
        })
}

export const retrieveCredit = (credit, selectedPaymentMethod, phoneNumber) => (dispatch, getState) => {
    dispatch({ type: 'RETRIEVE_CREDIT_REQUEST', credit, selectedPaymentMethod, phoneNumber })

    api.retrieveCredit(getToken(getState()), credit, selectedPaymentMethod, phoneNumber)
        .then((response) => {
            const { credit_after, buy_credit_before, buy_credit_after } = response.data
            dispatch({
                type: 'RETRIEVE_CREDIT_SUCCESS',
                creditAfter: credit_after
            })

            setTimeout(() => {
                if (buy_credit_before !== buy_credit_after) {
                    dispatch({ type: 'SET_BUY_CREDIT_SUCCESS', buyCredit: buy_credit_after })
                }
            }, 3000)
        })
        .catch((error) => {
            let failureMessage = 'Not enough credit'
            if (error.response && error.response.status === 422 && error.response.data.errors.hasOwnProperty('retrieveCredit')) {
                failureMessage = error.response.data.errors.retrieveCredit[0]
            }
            if (error.response && error.response.status === 422 && error.response.data.errors.hasOwnProperty('phoneNumber')) {
                failureMessage = error.response.data.errors.phoneNumber[0]
            }
            dispatch({ type: 'RETRIEVE_CREDIT_FAILURE', failureMessage })
        })
}


export const fetchCredit = () => (dispatch, getState) => {
    if (!getIsLogged(getState())) return
    dispatch({ type: 'GET_CREDIT_REQUEST' })
    let creditBefore = getCredit(getState())
    api.getCredit(getToken(getState()))
        .then((response) => {

            dispatch({
                type: 'GET_CREDIT_SUCCESS',
                creditAfter: response.data.credit,
                creditBefore: creditBefore,
                buyCreditAfter: response.data.buy_credit,
                verified: response.data.verified
            })
        })
        .catch((error) => {
            dispatch({ type: 'GET_CREDIT_FAILURE' })
        })
}
// bankDataValidation

export const getBanksOptions = () => (dispatch, getState) => {
    dispatch({ type: 'GET_BANKS_OPTIONS_REQUEST' })

    api.getBanksOptions(getToken(getState()))
        .then((response) => {
            const banksOptions = response.data
            dispatch({ type: 'GET_BANKS_OPTIONS_SUCCESS', banksOptions })
        })
        .catch((error) => {
                dispatch({ type: 'GET_BANKS_OPTIONS_FAILURE' })
            }
        )
}
export const getBankValidationInfo = () => (dispatch, getState) => {
    dispatch({ type: 'GET_BANK_VALIDATION_INFO_REQUEST' })

    let token = getToken(getState())
    api.getBankValidationInfo(getToken(getState()))
        .then((response) => {
            const bankDataValidationInfo = response.data
            dispatch({ type: 'GET_BANK_VALIDATION_INFO_SUCCESS', bankDataValidationInfo })
        })
        .catch((error) => {
                dispatch({ type: 'GET_BANK_VALIDATION_INFO_FAILURE' })
            }
        )
}

export const updateBankDataValidation = (formData) => (dispatch, getState) => {

    dispatch({ type: 'UPDATE_BANK_VALIDATION_REQUEST' })

    api.updateBankDataValidation(getToken(getState()), formData)
        .then((response) => {
            dispatch({ type: 'UPDATE_BANK_VALIDATION_SUCCESS', successMessage: 'VALIDATED_BANK_ACCOUNT' })
        })
        .catch((error) => {
            console.log(error)
            let errorMessage = ''
            if (error.response && error.response.status === 422) {
                if (error.response.data.errors.dep1) {
                    errorMessage += error.response.data.errors.dep1[0]
                }
                if (error.response.data.errors.dep2) {
                    errorMessage += error.response.data.errors.dep2[0]
                }
                if (error.response.data.errors.attempts) {
                    errorMessage += error.response.data.errors.attempts[0]
                }
            }
            if (error.response && error.response.status === 400) {
                errorMessage += 'WRONG_DEPOSIT_VALIDATION'
            }
            dispatch({ type: 'UPDATE_BANK_VALIDATION_FAILURE', failureMessage: errorMessage })
        })
}

export const userCreditChanged = (userId, creditBefore, creditAfter, buyCredit) => (dispatch, getState) => {
    if (userId !== getUserId(getState())) {
        return
    }
    dispatch({ type: 'USER_CREDIT_CHANGED', userId, creditBefore, creditAfter, buyCredit })
}

export const refreshUserJwtData = () => (dispatch, getState) => {
    if (!getIsLogged(getState())) {
        return
    }

    dispatch({ type: 'USER_REFRESH_JWT_DATA_REQUEST'})

    return api.refreshUserInformation()
        .then(res => {
            dispatch({ type: 'USER_REFRESH_JWT_DATA_SUCCESS', userJwtData: res.data })
        })
}
