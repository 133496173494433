import  SimplifiedResult  from '../SimplifiedResult';
import React from 'react'
import Slider from 'react-slick'
import ReactTooltip from 'react-tooltip'

const SimpleSlider = ({ items = [] }) => {
    console.log(items)
    /* //TODO:: review responsive adjustments */
    /* //TODO:: show real products */
    var settings = {
        className: 'center',
        // centerMode: true,
        infinite: true,
        centerPadding: '30px',
        slidesToShow: 5,
        speed: 500,
        swipeToSlide: true,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 1980,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1720,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }

    return (
        <Slider {...settings}>
            {items.map(item => <SimplifiedResult product={item}></SimplifiedResult>)}
        </Slider>
    )
}

export default SimpleSlider
