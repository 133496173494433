import React from 'react'

import { NoHits, Pagination, RefinementListFilter, SortingSelector } from 'searchkit'
import { defineMessages, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { getAvailableProductsCategories, getCurrentCollectableContext } from './store/products/reducers'
import { COLLECTABLE_CONTEXT_FAB, COLLECTABLE_CONTEXT_LORCANA, COLLECTABLE_CONTEXT_MTG, COLLECTABLE_CONTEXT_POKEMON } from './store/products/CollectableContext'

defineMessages({
    displayName: {
        id: 'displayName',
        description: 'displayName',
        defaultMessage: 'Product Name',
    },
    most_recently: {
        id: 'most_recently',
        description: 'most_recently',
        defaultMessage: 'Newest first',
    },
    most_older: {
        id: 'most_older',
        description: 'most_older',
        defaultMessage: 'Older first',
    },
    stock_high_to_low: {
        id: 'stock_high_to_low',
        description: 'stock_high_to_low',
        defaultMessage: 'Stock high to low',
    },
    stock_low_to_high: {
        id: 'stock_low_to_high',
        description: 'stock_low_to_high',
        defaultMessage: 'Stock low to high',
    },
    sale_price_high_to_low: {
        id: 'sale_price_high_to_low',
        description: 'sale_price_high_to_low',
        defaultMessage: 'Sale price high to low',
    },
    sale_price_low_to_high: {
        id: 'sale_price_low_to_high',
        description: 'sale_price_low_to_high',
        defaultMessage: 'Sale price low to high',
    },
    buy_price_high_to_low: {
        id: 'buy_price_high_to_low',
        description: 'buy_price_high_to_low',
        defaultMessage: 'Buy price high to low',
    },
    buy_price_low_to_high: {
        id: 'buy_price_low_to_high',
        description: 'buy_price_low_to_high',
        defaultMessage: 'Buy price low to high',
    },
    mtg_category_filter_label: {
        id: 'mtg_category_filter_label',
        description: 'mtg_category_filter_label',
        defaultMessage: 'Mtg type',
    },
    lorcana_category_filter_label: {
        id: 'lorcana_category_filter_label',
        description: 'lorcana_category_filter_label',
        defaultMessage: 'Lorcana type',
    },
    pokemon_category_filter_label: {
        id: 'pokemon_category_filter_label',
        description: 'pokemon_category_filter_label',
        defaultMessage: 'Pokemon type',
    },
    fab_category_filter_label: {
        id: 'fab_category_filter_label',
        description: 'fab_category_filter_label',
        defaultMessage: 'Flesh and Blood type',
    },
})
export const MySortingSelector = ({ intl }) => (
    <SortingSelector
        options={[
            {
                label: intl.formatMessage({
                    id: 'most_recently',
                }),
                field: 'saleItemUpdatedAtOnSale',
                order: 'desc',
                defaultOption: true,
            },
            // {
            //     label: intl.formatMessage({
            //         id: 'most_older',
            //     }),
            //     field: 'saleItemUpdatedAtOnSale',
            //     order: 'asc',
            //     defaultOption: false,
            // },
            {
                label: intl.formatMessage({
                    id: 'stock_high_to_low',
                }),
                field: 'quantityOnSale',
                order: 'desc',
                defaultOption: false,
            },
            {
                label: intl.formatMessage({
                    id: 'stock_low_to_high',
                }),
                field: 'quantityOnSale',
                order: 'asc',
                defaultOption: false,
            },
            {
                label: intl.formatMessage({
                    id: 'sale_price_high_to_low',
                }),
                field: 'minPriceSale',
                order: 'desc',
                defaultOption: true,
            },
            {
                label: intl.formatMessage({
                    id: 'sale_price_low_to_high',
                }),
                field: 'minPriceSale',
                order: 'asc',
                defaultOption: false,
            },
            // {
            //     label: intl.formatMessage({
            //         id: 'buy_price_high_to_low',
            //     }),
            //     field: 'minPriceBuy',
            //     order: 'desc',
            //     defaultOption: true,
            // },
            // {
            //     label: intl.formatMessage({
            //         id: 'buy_price_low_to_high',
            //     }),
            //     field: 'minPriceBuy',
            //     order: 'asc',
            //     defaultOption: false,
            // },
        ]}
    />
)

export const ProductCategorySelector = ({ intl }) => {
    const options = useSelector((state) => getAvailableProductsCategories(state))
    const currentCollectableContext = useSelector((state) => getCurrentCollectableContext(state))
    const firstOptionLabelKey = {
        [COLLECTABLE_CONTEXT_MTG]: 'mtg_category_filter_label',
        [COLLECTABLE_CONTEXT_LORCANA]: 'lorcana_category_filter_label',
        [COLLECTABLE_CONTEXT_POKEMON]: 'pokemon_category_filter_label',
        [COLLECTABLE_CONTEXT_FAB]: 'fab_category_filter_label',
    }[currentCollectableContext]

    const list = (props) => {
        return <select
            className='sk-select'
            value={props.selectedItems?.value}
            onChange={(selectedOption) => {
                props.setItems(options.find(opt => opt.value === selectedOption.target.value))
            }}>
            <option value={null}>{intl.formatMessage({
                id: firstOptionLabelKey
            }) + '... '}</option>
            {
                options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)
            }
        </select>
    }

    return <RefinementListFilter
        className='w-100'
        field='productCategory.raw'
        title={''}
        id='productCategory'
        operator='OR'
        showCount={false}
        listComponent={list}
    />
}


export const customHitStats = (props) => {
    const { hitsCount } = props
    return hitsCount
}

class Results extends React.Component {
    render() {
        return (
            <div className='results'>
                <div className='results-list'>
                    {this.props.hits}
                    <NoHits />
                </div>

                <div className='results-pagination' data-cy='catalog__pagination_catalog'
                     onClick={() => window.scrollTo(0, 0)}>
                    <Pagination showNumbers={true} pageScope={4} />
                </div>

                {/* <div className="results-catalog__page_size-selector" data-cy="catalog__page_size">
                        Results: <PageSizeSelector options={[25, 50, 100, 500]} listComponent={Select} />
                    </div> */}
            </div>
        )
    }
}

export default injectIntl(Results)
