import { BoolMust, TermsQuery } from 'searchkit'
import store from '../../index'
import { getCurrentCollectableContext } from './reducers'

export const COLLECTABLE_CONTEXT_MTG = 'MTG'
export const COLLECTABLE_CONTEXT_LORCANA = 'LORCANA'
export const COLLECTABLE_CONTEXT_POKEMON = 'POKEMON'
export const COLLECTABLE_CONTEXT_FAB = 'FAB'
export const COLLECTABLE_CONTEXT_ACCESSORY = 'ACCESSORY'

export const COLLECTABLE_CONTEXT_OPTIONS = [
    { label: 'Magic the gathering', value: COLLECTABLE_CONTEXT_MTG, snakeName: 'mtg' },
    { label: 'Lorcana', value: COLLECTABLE_CONTEXT_LORCANA, snakeName: 'lorcana' },
    { label: 'Pokemon', value: COLLECTABLE_CONTEXT_POKEMON, snakeName: 'pokemon' },
    { label: 'Flesh and Blood', value: COLLECTABLE_CONTEXT_FAB, snakeName: 'fab' },
]

export const getCollectableContextOptionByContextValue = (contextValue) => COLLECTABLE_CONTEXT_OPTIONS.find((opt) => opt.value === contextValue)

export const setCollectableContextFilter = (sk, collectableContext) => {
    if (!collectableContext) {
        return
    }
    sk.addDefaultQuery((query) => {
        return query.addFilter('collectableContext', BoolMust(TermsQuery('collectableContext.raw', [collectableContext, COLLECTABLE_CONTEXT_ACCESSORY])))
    })
}

export const getDefaultProductCategoryByCurrentContext = () => {
    const currentCollectableContext = getCurrentCollectableContext(store.getState())

    const config = {
        [COLLECTABLE_CONTEXT_MTG]: {
            value: null,
            label: 'Any',
        },
        [COLLECTABLE_CONTEXT_LORCANA]: {
            value: null,
            label: 'Any',
        },
        [COLLECTABLE_CONTEXT_POKEMON]: {
            value: null,
            label: 'Single',
        },
        [COLLECTABLE_CONTEXT_FAB]: {
            value: null,
            label: 'Single',
        },
    }

    return config[currentCollectableContext]
}
