import {
    getBuyItems,
    getBuyItemsExpansionsAvailableList,
    getBuyItemsLastFiltersUsed,
    getBuyItemsLastUserId,
    getBuyItemsRarityAvailableList,
    getBuyItemsTableInfo
} from '../store/buy/reducers'
import {
    getAvailableLangsIncludingAny,
    getAvailableProductsCategoriesIncludingAny,
    getAvailableSaleItemsOrderBy,
    getAvailableStatusIncludingAny,
    getBooleanOptions
} from '../store/products/reducers'
import React from 'react'
import { getBankData, getIsBusiness, getIsLogged, getUserId, getUserName, getVerified } from '../store/login/reducers'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../components/global/Translation'
import { deleteItemFromBuy, fetchBuyItems, fetchBuyItemsFromUser, updateQtyItemFromBuy } from '../store/buy/actions'
import Button from '../components/global/Button'
import confirm from '../components/global/confirm'
import LocalizedLink from '../components/global/LocalizedLink'
import { fetchUserPublicInfoById } from '../store/user/actions'
import { getUserPublicInfo } from '../store/user/reducers'
import { addToCart } from '../store/cart/actions'
import { getIsAdding } from '../store/cart/reducers'
import Alerts from '../components/alerts/Alerts'
import { showLoginModal } from '../store/ui/actions'
import { RenderBuyItemMobile } from '../components/buy/list/RenderBuyItemMobile'
import debounce from 'lodash/debounce'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getDefaultProductCategoryByCurrentContext } from '../store/products/CollectableContext'
import SaleItemsFilters from '../components/sale/items/SaleItemsFilters'
import isEqual from 'react-fast-compare'
import { parseFiltersToLaravel } from '../components/shared/filters/filters-utils'

const mapStateToProps = (state) => {
    return {
        buyItems: getBuyItems(state),
        buyTableInfo: getBuyItemsTableInfo(state),
        availableLangs: getAvailableLangsIncludingAny(state),
        availableStatus: getAvailableStatusIncludingAny(state),
        productsCategoriesOptions: getAvailableProductsCategoriesIncludingAny(state),
        booleanOptions: getBooleanOptions(state),
        expansionOptions: getBuyItemsExpansionsAvailableList(state),
        rarityOptions: getBuyItemsRarityAvailableList(state),
        userListInfoDistinctCurrentUser: getUserPublicInfo(state),
        addingToCart: getIsAdding(state),
        userIdLogged: getUserId(state),
        userName: getUserName(state),
        isLogged: getIsLogged(state),
        verified: getVerified(state),
        bankData: getBankData(state),
        isBusiness: getIsBusiness(state),
        availableOrderByOptions: getAvailableSaleItemsOrderBy(state),
        lastUserId: getBuyItemsLastUserId(state),
        lastFiltersUsed: getBuyItemsLastFiltersUsed(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const userToFilter = ownProps.match.params.id

    return {
        fetchDistinctUserPublicInfo: () => {
            dispatch(fetchUserPublicInfoById(userToFilter))
        },
        fetchBuyItems: (newPage, pageSize, filters, orderBy) => {
            if (userToFilter) {
                dispatch(fetchBuyItemsFromUser(userToFilter, newPage, pageSize, filters, orderBy))
            } else {
                dispatch(fetchBuyItems(newPage, pageSize, filters, orderBy))
            }
        },
        deleteItemFromBuy: (itemId) => {
            return dispatch(deleteItemFromBuy(itemId))
        },
        updateQtyItemFromBuy: (itemId, qty) => {
            return dispatch(updateQtyItemFromBuy(itemId, qty))
        },
        addToCart: (productId, qty, locale) => {
            return dispatch(addToCart(productId, qty, locale))
        },
        onShowUpLogin: () => {
            dispatch(showLoginModal())
        }
    }
}
const confirmDeleteItem = (itemId, item, onDeleteItem, intl) => {
    return confirm(intl.formatMessage({ id: 'CONFIRM_DELETE_ITEM_FROM_BUY' })).then(
        (confirm) => {
            return onDeleteItem(itemId)
        },
        (cancel) => {
            return Promise.reject()
        }
    )
}

const getDefaultFilters = () => {
    return {
        search: '',
        selectedLang: {
            value: null,
            label: 'Any'
        },
        selectedExpansion: {
            value: null,
            label: 'Any'
        },
        selectedRarity: {
            value: null,
            label: 'Any'
        },
        productCategory: getDefaultProductCategoryByCurrentContext(),
        selectedCondition: null,
        priceMin: 0.0,
        priceMax: 0.0,
        isFoil: {
            value: null,
            label: 'Any'
        },
        isSigned: {
            value: null,
            label: 'Any'
        },
        isPlayset: {
            value: null,
            label: 'Any'
        },
        isAltered: {
            value: null,
            label: 'Any'
        }
    }
}

class BuyItemsScreen extends React.Component {
    booleanOptions = [
        {
            label: 'Any',
            value: null
        },
        {
            label: 'Yes',
            value: true
        },
        {
            label: 'No',
            value: false
        }
    ]

    state = {
        filters: {
            ...getDefaultFilters()
        }
    }

    constructor() {
        super()
        this.searchBuyItems = debounce(this.searchBuyItems.bind(this), 400)
    }


    componentDidMount() {
        if (!this.isUserListEqualToCurrentLogged()) {
            this.props.fetchDistinctUserPublicInfo()
        }

        this.loadQueryParamsToFiltersAndOrderBy(
            () => {
                const isSameLastFilters = isEqual(parseFiltersToLaravel(this.state.filters), this.props.lastFiltersUsed)

                if (
                    parseInt(this.getUserId()) !== parseInt(this.props.lastUserId)
                    || !this.props.saleItems?.length
                    || isEqual(this.state.filters, getDefaultFilters())
                    || !isSameLastFilters
                ) {
                    return this.searchBuyItems()
                }
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.collectableContext !== this.props.collectableContext) {
            this.resetFiltersValue(() => this.searchSaleItems())
        }

        if (!isEqual(prevState.filters, this.state.filters) || !isEqual(prevState.orderBy, this.state.orderBy)) {
            this.transformFiltersAndOrderByToQueryParams()
        }
    }


    loadQueryParamsToFiltersAndOrderBy(callback = null) {
        const queryParams = new URLSearchParams(this.props.location.search)

        const filters = queryParams.get('filters') ? JSON.parse(queryParams.get('filters')) : {}

        const orderBy = queryParams.get('orderBy') ? JSON.parse(queryParams.get('orderBy')) : null

        this.setState(
            { filters: { ...this.state.filters, ...filters }, orderBy: orderBy ?? this.state.orderBy },
            () => {
                if (callback) {
                    callback()
                }
            }
        )
    }

    transformFiltersAndOrderByToQueryParams() {
        const isFilterActive = (value) => {
            if (value === null || value === undefined || value === '') {
                return false
            }

            if (typeof value === 'object' && !value.value) {
                return false
            }
            return true
        }

        const queryParams = new URLSearchParams()

        const filters = {}

        // Set each filter dynamically in query params
        Object.entries(this.state.filters).forEach(([key, value]) => {
            if (isFilterActive(value)) {
                filters[key] = value
            }
        })

        queryParams.set('filters', JSON.stringify(filters))

        if (this.state.orderBy) {
            queryParams.set('orderBy', JSON.stringify(this.state.orderBy))
        }

        this.props.history.replace({ pathname: this.props.location.pathname, search: queryParams.toString() })
    }

    resetFiltersValue(callback = null) {
        this.setState({
            ...this.state,
            filters: {
                ...getDefaultFilters()
            }
        }, () => {
            if (callback) {
                callback()
            }
        })
    }

    getUserId() {
        return this.props.match.params.id ?? this.props.userIdLogged
    }

    isUserListEqualToCurrentLogged() {
        if (!this.props.match.params.id) {
            return true
        }
        //Dont put triple equal on this line
        return this.props.match.params.id && this.props.match.params.id == this.props.userIdLogged
    }

    isUserItemEqualToCurrentLogged(item) {
        return this.props.userIdLogged == item.user_id
    }

    canAddItem() {
        return this.props.isLogged && this.isUserListEqualToCurrentLogged()
    }

    searchBuyItems(page = 1, pageSize = 10) {
        this.props.fetchBuyItems(page, pageSize, parseFiltersToLaravel(this.state.filters), this.state.orderBy?.value)
    }

    setFilterValue = (filterKey, value) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [filterKey]: value
            }
        }, () => {
            this.searchBuyItems()
        })
    }

    setOrderByValue = (orderBy) => {
        this.setState({
                orderBy
            }, () => this.searchBuyItems()
        )
    }

    render() {

        if (this.props.isLogged && this.isUserListEqualToCurrentLogged() && !this.props.bankData?.validated) {
            return (
                <div className='col-12'>
                    <Alerts alert={'FILL_FINANCIAL_INFO_TO_TRADE'} status={'warning'} />
                </div>
            )
        }

        const buyItems = this.props.buyItems ?? []

        const title = this.isUserListEqualToCurrentLogged() ? 'My buy list' : `${this.props.userListInfoDistinctCurrentUser?.name || ''} buy list`

        return (
            <div>
                <div className='container-fluid'>
                    <div className='row' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className='col-12 col-md-4'>
                            <h2>{title}</h2>
                        </div>
                        {this.canAddItem() && (
                            <div className='col-12 col-md-3 col-lg-2'>
                                <LocalizedLink routeId={'ROUTE_USER_DASHBOARD'} params={{ view: 'add-to-buy', id: '' }}>
                                    <Button icon='plus-circle'>
                                        <Translation id='NEW_ITEM' defaultMessage='New item' />
                                    </Button>
                                </LocalizedLink>
                            </div>
                        )}
                    </div>
                </div>

                <div className='container-fluid'>
                    <h4 className='card-title'>
                        <Translation id='filters' defaultMessage='Filters' />
                    </h4>
                    <div className='mt-3 mb-3'>
                        <SaleItemsFilters
                            filters={this.state.filters}
                            orderBy={this.state.orderBy}
                            expansionOptions={this.props.expansionOptions}
                            rarityOptions={this.props.rarityOptions}
                            productsCategoriesOptions={this.props.productsCategoriesOptions}
                            availableLangs={this.props.availableLangs}
                            availableStatus={this.props.availableStatus}
                            booleanOptions={this.booleanOptions}
                            availableOrderByOptions={this.props.availableOrderByOptions}
                            shouldDisplayProductCategoryFilter={this.state.shouldDisplayProductCategoryFilter}
                            setFilters={this.setFilterValue}
                            setOrderBy={this.setOrderByValue}
                            onSubmit={this.searchSaleItems}
                            onReset={() => this.resetFiltersValue(() => this.searchBuyItems())}
                        />
                    </div>

                    <div className='card-list'>
                        <InfiniteScroll
                            dataLength={buyItems.length}
                            next={() => this.props.buyTableInfo.page < this.props.buyTableInfo.pagesCount && this.searchBuyItems(this.props.buyTableInfo.page + 1, 10)}
                            hasMore={this.props.buyTableInfo.page < this.props.buyTableInfo.pagesCount}
                            loader={<h4>Loading...</h4>}
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>You have seen it all</b>
                                </p>
                            }
                        >
                            {buyItems.map((item) => {
                                return (
                                    <RenderBuyItemMobile
                                        key={item.id}
                                        item={item}
                                        intl={this.props.intl}
                                        onShowUpLogin={this.props.onShowUpLogin}
                                        isLogged={this.props.isLogged}
                                        userName={this.props.userName}
                                        bankData={this.props.bankData}
                                        isVerified={this.props.verified}
                                        isUserItemEqualToCurrentLogged={this.isUserListEqualToCurrentLogged(item)}
                                        updateQty={(id, qty) => {
                                            this.props.updateQtyItemFromBuy(id, qty).then(() => this.searchBuyItems())
                                        }}
                                        addToCart={(id, qty) => this.props.addToCart(id, qty, this.props.intl).then(() => this.searchBuyItems())}
                                        deleteSaleItem={(id, original) => confirmDeleteItem(id, original, this.props.deleteItemFromBuy, this.props.intl).then(() => this.searchBuyItems())}
                                    />
                                )
                            })}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(BuyItemsScreen)))
