import React from 'react'

import { defineMessages, injectIntl } from 'react-intl'

import logo from '../../logo-horizontal.svg'
import SignInForm from './SignInForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import CreateAccountForm from './CreateAccountForm'
// import MandatoryChangePasswordForm from './MandatoryChangePasswordForm'
import Translation from '../global/Translation'
import LocalizedLink from '../global/LocalizedLink'
defineMessages({
    WRONG_CREDENTIALS: {
        id: 'WRONG_CREDENTIALS',
        description: 'WRONG_CREDENTIALS',
        defaultMessage: 'Wrong credentials',
    },
})

class LoginModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = { forgotPassword: null, createAccount: null }
    }
    showForgotPassword = () => {
        this.setState({ forgotPassword: true })
    }
    showLogin = () => {
        this.setState({ forgotPassword: null, createAccount: null })
    }
    componentDidUpdate(prevProps, prevState) {

        if (this.props.show && !prevProps.show) {
            this.setState({ forgotPassword: null })
        }
    }

    render() {
        const { show, onClose, isLogging } = this.props

        const form = this.state.forgotPassword ? (
            <ForgotPasswordForm showLogin={this.showLogin} onRecoverPassword={this.props.onRecoverPassword} />
        ) : (
            <SignInForm
                isLogging={isLogging}
                onLogin={this.props.onLogin}
                showForgotPassword={this.showForgotPassword}
                showCreateAccount={this.showCreateAccount}
                callback={this.props.callback}
                callbackCenter={this.props.callbackCenter}
                onClose={this.props.onClose}
            />
        )

        return (
            <div>
                <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} onClick={onClose} />
                <div id="modal-login-container" className="modal-login" style={{ display: show ? 'block' : 'none' }}>
                    <div className="container-fluid">
                        <div className="row">
                            {/* <div className="col-12 logo-container d-none d-lg-block"></div> */}
                            <div className="col-12 card-container">
                                <header className="container-fluid">
                                    <div className="row">
                                        <div className="logo-container col-9 offset-1">
                                            <img alt="" id="profile-img" className="profile-img-card filter-white" src={logo} />
                                        </div>
                                        <div className="col-1  my-auto text-right">
                                            <div className="bt-close" onClick={onClose}>
                                                <i className="fal fa-times" />
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                {form}
                            </div>
                        </div>
                    </div>
                    <div class="register-footer">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            First time around? Join us
                            <LocalizedLink routeId="ROUTE_REGISTER" onClick={onClose}>
                                <div style={{ display: 'flex' }}>
                                    &nbsp;
                                    <Translation id="REGISTER" defaultMessage="Register"></Translation>{' '}
                                    <span className="material-icons-outlined" style={{ color: 'white', fontSize: '24px',fontWeight:'200' }}>
                                        arrow_forward
                                    </span>
                                </div>
                            </LocalizedLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default injectIntl(LoginModal)
