import { retrieveCredit } from './../store/login/actions'
import { getBankData, getCredit, getUserId, getUserName } from './../store/login/reducers'
import React, { useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../components/global/Translation'
import Button from '../components/global/Button'
import Alerts from '../components/alerts/Alerts'


const RetrieveCreditScreen = (props) => {
    const dispatch = useDispatch()
    const [credit_to_retrieve, setCredit_to_retrieve] = useState(0)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('bank')
    const userName = useSelector((state) => getUserName(state))
    const userId = useSelector((state) => getUserId(state))
    const credit = useSelector((state) => getCredit(state))
    const bankData = useSelector((state) => getBankData(state))


    const onSubmit = (values, action) => {
        dispatch(retrieveCredit(values, selectedPaymentMethod,phoneNumber))
    }

    //TODO: depende de si la retirada se hace por paynow o por banco. si es por banco necesita los datos bancarios.
    if(!bankData || !bankData.validated) {
        return  <Alerts alert={"FILL_FINANCIAL_INFO_TO_TRADE"} status={"warning"} />
    }


    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 ">
                        <h2>
                            <Translation id="current_credit" defaultMessage="Current credit" /> S${parseFloat(credit).toFixed(2)}
                        </h2>
                    </div>
                </div>

                <form className="form" onSubmit={() => this.onSubmit()}>
                    {/*change password*/}
                    <div className="col-md-6 col-lg-3 col-">
                        <h3>
                            <Translation id="retrieve_credit" defaultMessage="Retrieve credit" />
                        </h3>
                        <label htmlFor="buy-credit" className="form-label">
                            <Translation
                                id="retrieve_credit_long"
                                defaultMessage="Specify the credit you want to retrieve, it will be ingressed on the account you specified in the financial information"
                            />
                        </label>
                        <div>
                            Bank name: {bankData && bankData.bank.name}
                            <br />
                            Account holder: {bankData && bankData.account_holder}
                            <br />
                            Account number: {bankData && bankData.account_number}
                            <br />
                        </div>
         
                        <label htmlFor="phone_number" className="form-label">
                            <strong>
                                <Translation id="phone_number" defaultMessage="PhoneNumber" />
                            </strong>
                        </label>
                        <input
                            className="form-control"
                            placeholder={'+65 xxxx xxxxx'}
                            onKeyPress={(e) => {
                                const value = e.target.value + e.key
                                if (!/^[\+]?[0-9]*$/.test(value)) e.preventDefault()
                                // if (!/[+0-9]/.test(e.key)) e.preventDefault()
                            }}
                            id="phone"
                            name="retrieve_credit"
                            required="required"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            defaultValue={phoneNumber}
                        />

                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="paynow"
                                checked={selectedPaymentMethod === 'paynow'}
                                onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                            />
                            <label className="form-check-label">Paynow</label>
                        </div>
                        <label htmlFor="retrieve_credit" className="form-label">
                            <strong>
                                <Translation id="how_many_money_want_you_to_retrieve" defaultMessage="How many credit do you want to retrieve?" />
                            </strong>
                        </label>
                        <input
                            className="form-control"
                            type="number"
                            id="buyCredit"
                            name="retrieve_credit"
                            required="required"
                            onChange={(e) => setCredit_to_retrieve(e.target.value)}
                            defaultValue={credit_to_retrieve}
                        />

                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="bank"
                                checked={selectedPaymentMethod === 'bank'}
                                onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                            />
                            <label className="form-check-label">Bank</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <Button type="submit" value="Update" onClick={() => onSubmit(credit_to_retrieve)}>
                                <Translation id="send" defaultMessage="Send" />
                            </Button>
                        </div>
                    </div>
                </form>
                <div className="row">
                    <Alerts status="warning" alert={`Attention, ${userName} the transferred money may take 1-3 days to be reflected in your bank account. `}></Alerts>
                </div>
            </div>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(RetrieveCreditScreen)))
