import React from 'react'
import Translation from '../global/Translation'
import { injectIntl } from 'react-intl'
import AlertsContainer from '../alerts/AlertsContainer'
import Spinner from '../global/Spinner'
import Alerts from '../alerts/Alerts'
import LocalizedLink from '../global/LocalizedLink'

class SignInForm extends React.Component {
    handleSubmit = e => {
        e.preventDefault()
        this.props.onLogin(this.usernameInput.value, this.passwordInput.value, this.props.callback, this.props.callbackCenter, this.props.intl, null)
    }

    render() {
        const { isLogging, onClose } = this.props

        const spinner = isLogging ? <Spinner isLoading={isLogging} /> : null

        const {autofocusEnabled = true} = this.props

        return (
            <div className="form-signin">
                <form onSubmit={this.handleSubmit}>
                    <AlertsContainer status={300} />
                    {spinner}
                    <label htmlFor="email">
                        <Translation id="username" defaultMessage="Username" />
                    </label>
                    <input
                        type="text"
                        id="inputEmail"
                        className="form-control"
                        placeholder={this.props.intl.formatMessage({ id: 'username' })}
                        autoFocus={autofocusEnabled ?? true}
                        ref={(node) => {
                            this.usernameInput = node
                        }}
                    />
                    <label htmlFor="email">
                        <Translation id="password" defaultMessage="Password" />
                    </label>
                    <input
                        type="password"
                        id="inputPassword"
                        className="form-control"
                        placeholder="Password"
                        ref={(node) => {
                            this.passwordInput = node
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                this.handleSubmit(e)
                            }
                        }}
                    />
                    <div id="remember" className="checkbox" />
                    <input type="submit" className="bt bt-inverted loginbutton" value={this.props.intl.formatMessage({ id: 'LOGIN' })} />

                    <div className="row links">
                        <div className="col-12">
                            <span style={{ cursor: 'pointer' }} onClick={this.props.showForgotPassword}>
                                <Translation id="forgot_password" defaultMessage="Forgot your password?" />
                            </span>
                        </div>
                    </div>
                </form>

            </div>
        )
    }
}

export default injectIntl(SignInForm)
