import React from 'react'
import { connect, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter,Route,Switch } from 'react-router-dom'
import AddToBuyForm from '../components/buy/AddToBuyForm'
import { getBankData } from '../store/login/reducers'
import Alerts from '../components/alerts/Alerts'

const AddToBuyScreen = (props, intl) => {

    const bankData = useSelector(state => getBankData(state))

    if(!bankData?.validated)
    {
        return (
            <div className='col-12'>
                <Alerts alert={"FILL_FINANCIAL_INFO_TO_TRADE"} status={"warning"} />
            </div>
        )
    }
    return (
        <div className="container-fluid">
            <AddToBuyForm goBackAfterCreate={true}></AddToBuyForm>
        </div>
    )
}

export default withRouter(injectIntl(connect()(AddToBuyScreen)))
