import React, { useState } from 'react'
import { SearchBox } from 'searchkit'
import classNames from 'classnames'

export const HeaderSearchBoxNotHome = ({ intl, handleSubmit, setRef }) => {
    const [isFocused, setIsFocused] = useState(false)
    return <div className={classNames({
        'sk-search-box': true,
        'is-focused': isFocused
    })}>
        <form onSubmit={handleSubmit}>
            <div className='sk-search-box__icon'></div>
            <input
                ref={setRef}
                type='text'
                placeholder={intl.formatMessage({ id: 'im_looking_for' })}
                data-qa='query'
                className='sk-search-box__text'
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />

            <div data-qa='loader'
                 className='sk-search-box__loader sk-spinning-loader is-hidden'></div>
        </form>
    </div>
}


export const HeaderSearch = ({ isInCatalog, intl, setRef, handleSubmit }) => {
    const setSearchFocus = React.useCallback(() => {
        const inputSelectorClass = document.getElementsByClassName('sk-search-box__text')
        if (inputSelectorClass.length) {
            const inputSelector = inputSelectorClass[0]
            inputSelector.focus()
        }
    })

    return (
        <div onClick={setSearchFocus}>
            {
                isInCatalog ?
                    <SearchBox
                        autofocus={window.screen.width > 768}
                        searchThrottleTime={1000}
                        searchOnChange={true}
                        queryFields={['name', 'setCode', 'setName']}
                        prefixQueryFields={['name', 'setCode', 'setName']}
                        queryOptions={{ default_operator: 'AND' }}
                        placeholder={'Search'}
                    />
                    :
                    <HeaderSearchBoxNotHome
                        intl={intl}
                        handleSubmit={handleSubmit}
                        setRef={setRef}
                    />

            }
            <span id='header-search-collapse__button' className='material-icons-outlined'>chevron_left</span>
        </div>
    )
}
