import { getProductFieldsByCategory } from './../../../store/products/reducers';
import React from 'react'
import DisplayLanguage from '../DisplayLanguage'
import DisplayStatus from '../DisplayStatus'
import Translation from '../../global/Translation'
import ReactTooltip from 'react-tooltip'
import DisplayExpansion from '../DisplayExpansion'
import { useSelector } from 'react-redux'

export const RenderProductSingleProperties = ({ original, showExpansion = false }) => {
const fields = useSelector((state) => getProductFieldsByCategory(state))
// fields[original.productCategory].includes('expansion')

    return (
        <React.Fragment>
            {fields[original.product.productCategory].includes('expansion') && showExpansion && (
                <div key={original.product}>
                    <DisplayExpansion product={original.product}></DisplayExpansion>
                </div>
            )}
            {fields[original.product.productCategory].includes('language') && (
                <div key={original.language}>
                    <DisplayLanguage lang={original.language}></DisplayLanguage>
                </div>
            )}
            {fields[original.product.productCategory].includes('status') && (
                <div className="status" key={original.state}>
                    <DisplayStatus status={original.state}></DisplayStatus>
                </div>
            )}
            {fields[original.product.productCategory].includes('foil') && !!original.is_foil && (
                <div className="badge badge-property" key="isFoil">
                    <span>
                        <Translation id="Foil" defaultMessage="Foil" />
                    </span>
                </div>
            )}
            {fields[original.product.productCategory].includes('signed') && !!original.is_signed && (
                <div className="badge badge-property" key='isSigned'>
                    <span>
                        <Translation id="Signed" defaultMessage="Signed" />
                    </span>
                </div>
            )}
            {fields[original.product.productCategory].includes('playset') && !!original.is_playset && (
                <div className="badge badge-property">
                    <span>
                        <Translation id="Playset" defaultMessage="Playset" />
                    </span>
                </div>
            )}
            {fields[original.product.productCategory].includes('altered') && !!original.is_altered && (
                <div className="badge badge-property">
                    <span>
                        <Translation id="Alterado" defaultMessage="Altered" />
                    </span>
                </div>
            )}
            {fields[original.product.productCategory].includes('graded') && !!original.is_graded && (
                <div className="badge badge-property">
                    <span>
                        <Translation id="Graded" defaultMessage="Graded" />
                    </span>
                </div>
            )}
            {fields[original.product.productCategory].includes('comments') && !!original.comments && (
                <div>
                    <ReactTooltip backgroundColor={'black'} id={'comment-' + original.id} effect="solid">
                        <span>{original.comments} </span>
                    </ReactTooltip>
                    {original.comments && <i className="fa fa-comment fa-lg" data-tip data-for={'comment-' + original.id}></i>}
                </div>
            )}
            {fields[original.product.productCategory].includes('image_front') &&!!original.image_front_url && (
                <div>
                    <ReactTooltip backgroundColor={'black'} id={'front-url-' + original.id} effect="solid">
                        <img style={{ width: 150 }} src={original.image_front_url} />
                    </ReactTooltip>
                    <a href={original.image_front_url} target="_blank">
                        {original.image_front_url && <i className="far fa-image fa-lg cursor-pointer" data-tip data-for={'front-url-' + original.id}></i>}
                    </a>
                </div>
            )}
            {fields[original.product.productCategory].includes('image_back') &&!!original.image_back_url && (
                <div>
                    <ReactTooltip backgroundColor={'black'} id={'back-url' + original.id} effect="solid">
                        <img style={{ width: 150 }} src={original.image_back_url} />
                    </ReactTooltip>
                    <a href={original.image_back_url} target="_blank">
                        {original.image_back_url && <i className="far fa-image fa-lg" data-tip data-for={'back-url' + original.id}></i>}
                    </a>
                </div>
            )}
        </React.Fragment>
    )
}
