import Translation from '../../global/Translation'
import React, { useState } from 'react'
import noImage from '../../../assets/img/advanced-search/advanced-search.jpg'

export const LorcanaProductSingleDetails = ({ product, intl, goTo }) => {

    return (
        <div className="row">
            <h3>{product.name}</h3>
            <div className="col-12 col-lg-4 mt-2">
                <img
                    src={product.img}
                    className="card-image"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = noImage
                        return true
                    }}
                ></img>
            </div>
            <div className="col-12 col-lg-8 mt-1" style={{ fontWeight: '300' }}>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <dl className="labeled row no-gutters m-0">
                        <dt className="col-5">
                            <Translation id="card_name" defaultMessage="Card Name" />:
                        </dt>
                        <dd className="col-7">{product.name}</dd>

                        <dt className="col-5">
                            <Translation id="cost" defaultMessage="Cost" />:
                        </dt>
                        <dd className="col-7">{product.cost}</dd>

                        <dt className="col-5">
                            <Translation id="lore" defaultMessage="Lore" />:
                        </dt>
                        <dd className="col-7">{product.lore}</dd>

                        <dt className="col-5">
                            <Translation id="classifications" defaultMessage="Classifications" />:
                        </dt>
                        <dd className="col-7">{product.classifications}</dd>

                        {!!product.abilities &&
                            <React.Fragment>
                                <dt className='col-5'>
                                    <Translation id='abilities' defaultMessage='Abilities' />:
                                </dt>
                                <dd className='col-7'>{product.abilities}</dd>
                            </React.Fragment>
                        }

                        <dt className='col-5'>
                            <Translation id='types' defaultMessage='Types' />:
                        </dt>
                        <dd className="col-7">{product.type}</dd>

                        <dt className="col-5">
                            <Translation id="card_text" defaultMessage="Card Text" />:
                        </dt>
                        <dd
                            className="col-7"
                            style={{
                                maxHeight: '110px',
                                overflowY: 'auto',
                            }}
                        >
                            {product.text}
                        </dd>

                        {!!product.strength && (
                            <React.Fragment>
                                <dt className="col-5">
                                    <Translation id="strength" defaultMessage="Strength" />:
                                </dt>
                                <dd className="col-7">{product.strength}</dd>
                            </React.Fragment>
                        )}

                        {!!product.willpower && (
                            <React.Fragment>
                                <dt className="col-5">
                                    <Translation id="willpower" defaultMessage="Willpower" />:
                                </dt>
                                <dd className="col-7">{product.willpower}</dd>
                            </React.Fragment>
                        )}

                        <dt className="col-5">
                            <Translation id="rarity" defaultMessage="Rarity" />:
                        </dt>
                        <dd className="col-7">{product.rarity}</dd>

                        <dt className="col-5">
                            <Translation id="set" defaultMessage="set" />:
                        </dt>
                        <dd className="col-7">
                            {product.setName}
                        </dd>

                        <dt className="col-5">
                            <Translation id="card_number" defaultMessage="Card Number" />:
                        </dt>
                        <dd className="col-7">{product.number}</dd>

                        <dt className="col-5">
                            <Translation id="artist" defaultMessage="Artist" />:
                        </dt>
                        <dd className="col-7">{product.artist}</dd>
                    </dl>
                </div>
            </div>
        </div>
    )
}
