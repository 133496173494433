import { getLastAddedItemsByContext } from './store/sale/reducers'
import { fetchLastProductsAdded } from './store/sale/actions'
import { setCollectableContext } from './store/products/actions'
import { COLLECTABLE_CONTEXT_OPTIONS, getCollectableContextOptionByContextValue } from './store/products/CollectableContext'
import jaceBg from './assets/img/jace-background.jpeg'
import LocalizedLink from './components/global/LocalizedLink'
import Alerts from './components/alerts/Alerts'
import Spinner from './components/global/Spinner'
import SimplifiedResult from './components/product/SimplifiedResult'
import SimpleSlider from './components/product/slider/SimpleSlider'
import ReactTooltip from 'react-tooltip'
import React from 'react'
import { getFetchedProducts } from './store/products/reducers'
import { connect } from 'react-redux'
import { Hits, ResetFilters, SearchBox, SearchkitManager, SearchkitProvider, InitialLoader } from 'searchkit'
import Results from './Results'
import { defineMessages, injectIntl } from 'react-intl'

import Select from 'react-select'

const mapStateToProps = (state) => {
    return {
        lastAddedByContext: getLastAddedItemsByContext(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCollectableContext: (context) => {
            dispatch(setCollectableContext(context))
        },
        fetchLastProductsAdded: (context) => {
            dispatch(fetchLastProductsAdded(context))
        },
    }
}

defineMessages({
    SEARCH_BY_NAME_OR_WHATEVER: {
        id: 'select',
        description: 'select',
        defaultMessage: 'Select',
    },
})
const CtaGame = ({ game, ...props }) => {
    return (
        <div className="home-games-cta">
            <div
                onClick={() => props.setCollectableContext(game.value)}
                className="home-games-cta__game"
                key={game.snakeName}
                style={{
                    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/home/${game.snakeName}_background-min.png')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    width: '100%',
                }}
            >
                <div className="home-games-cta__body">
                    <h1>Do you have what it takes to be the first seller of {game.label} in C&C?</h1>
                    <h2 style={{ fontWeight: '200' }}>We are looking for you! Put your cards on sale and win the First Seller batch in your profile.</h2>
                    <img className="home-games-cta__logo" src={`/home/${game.snakeName}_logo-min.png`} style={{ width: '240px' }} />
                </div>

                <img className="home-games-cta__cards" src={`/home/${game.snakeName}_cards-min.png`} />
            </div>
        </div>
    )
}
const SelectorGame = ({ game, ...props }) => {
    return (
        <div
            onClick={() => props.setCollectableContext(game.value)}
            className="home-games-selector__game"
            key={game.snakeName}
            style={{
                background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/home/${game.snakeName}_background-min.png')`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            }}
        >
            <img className="home-games-selector__logo" src={`/home/${game.snakeName}_logo-min.png`} style={{ width: '70%' }} />
            <img className="home-games-selector__cards" src={`/home/${game.snakeName}_cards-min.png`} />
        </div>
    )
}
class Home extends React.Component {
    componentDidMount = () => {
        this.props.fetchLastProductsAdded('MTG')
        this.props.fetchLastProductsAdded('LORCANA')
        this.props.fetchLastProductsAdded('POKEMON')
        this.props.fetchLastProductsAdded('FAB')
    }
    render() {
        let pokemonGame = getCollectableContextOptionByContextValue('POKEMON')
        let lorcanaGame = getCollectableContextOptionByContextValue('LORCANA')
        let fabGame = getCollectableContextOptionByContextValue('FAB')

        return (
            <section id="home">
                <div className="container-fluid g-0 m-0 p-3">
                    <div className="home-games-selector">
                        {COLLECTABLE_CONTEXT_OPTIONS.map((game) => {
                            return <SelectorGame game={game} {...this.props}></SelectorGame>
                        })}
                    </div>
                </div>
                <div className="container-fluidx" style={{ width: '100%', backgroundColor: 'unset' }}>
                    {COLLECTABLE_CONTEXT_OPTIONS.map((game) => {
                        if (
                            this.props.lastAddedByContext &&
                            this.props.lastAddedByContext[game.value] &&
                            this.props.lastAddedByContext[game.value].products &&
                            this.props.lastAddedByContext[game.value].products[0] &&
                            this.props.lastAddedByContext[game.value].products[0].quantityOnSale > 0
                        )
                            return (
                                <React.Fragment>
                                    <h3 style={{ fontWeight: '200', margin: '16px 0' }}>LAST ITEM ADDED ON {game.label.toUpperCase()}</h3>
                                    {this.props.lastAddedByContext && this.props.lastAddedByContext[game.value] && (
                                        <SimpleSlider items={this.props.lastAddedByContext[game.value].products} />
                                    )}
                                </React.Fragment>
                            )
                        else {
                            return <CtaGame game={game} {...this.props}></CtaGame>
                        }
                            
                    })}
                </div>
                {/* <CtaGame game={pokemonGame} {...this.props}></CtaGame>
                <CtaGame game={lorcanaGame} {...this.props}></CtaGame>
                <CtaGame game={fabGame} {...this.props}></CtaGame> */}
            </section>
        )
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Home))
