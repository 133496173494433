import { filterItems } from './utils'
import Select from 'react-select'
import React from 'react'
import Spinner from '../global/Spinner'
import Modal from '../global/Modal'
import Translation from '../global/Translation'
import Button from '../global/Button'
import AddToSaleForm from '../sale/AddToSaleForm'
import AddToBuyForm from '../buy/AddToBuyForm'
import AddToCollectionForm from '../collection/AddToCollectionForm'
import { defineMessages, injectIntl } from 'react-intl'
import Alerts from '../alerts/Alerts'
import { ProductSaleItem } from './items/ProductSaleItem'
import { ProductBuyItem } from './items/ProductBuyItem'
import { ProductSingleDetails } from './renderProductSingleInfo/ProductSingleDetails'
import SignInForm from '../login-modal/SignInForm'
import { LoginInLineContainer } from '../login-in-line/LoginInLineContainer'
import { ProductSealedDetails } from './renderProductSealedInfo/ProductSealedDetails'
import { ProductAccessoryDetails } from './renderProductAccessoryInfo/ProductAccessoryDetails'
import { LorcanaProductSingleDetails} from './renderLorcanaSingleInfo/LorcanaProductSingleDetails'
import { PokemonProductSingleDetails} from './renderPokemonSingleInfo/PokemonProductSingleDetails'
import { PokemonProductSealedDetails } from './renderPokemonSealedInfo/PokemonProductSealedDetails'
import { FabProductSingleDetails } from './renderFabSingleInfo/FabProductSingleDetails'

defineMessages({
    VERIFY_YOUR_EMAIL: {
        id: 'VERIFY_YOUR_EMAIL',
        description: 'VERIFY_YOUR_EMAIL',
        defaultMessage: 'Please verify your email address'
    }
})

const defaultFiltersValue = {
    selectedItemType: {
        value: 'sale',
        label: 'Showing sellers'
    },
    selectedLang: {
        value: null,
        label: 'Any'
    },
    selectedCondition: null,
    priceMin: 0.0,
    priceMax: 0.0,
    isFoil: {
        value: null,
        label: 'Any'
    },
    isSigned: {
        value: null,
        label: 'Any'
    },
    isPlayset: {
        value: null,
        label: 'Any'
    },
    isAltered: {
        value: null,
        label: 'Any'
    }
}

class Product extends React.Component {
    booleanOptions = [
        {
            label: 'Any',
            value: null
        },
        {
            label: 'Yes',
            value: true
        },
        {
            label: 'No',
            value: false
        }
    ]
    componentDidMount = () => {
        this.props.fetchProduct(this.props.id)
        this.props.fetchProductSales(this.props.id)
        this.props.fetchProductBuys(this.props.id)
    }

    componentDidUpdate(prevProps) {
        if (this.props.userId !== prevProps.userId) {
            this.props.fetchProduct(this.props.id)
            this.props.fetchProductSales(this.props.id)
            this.props.fetchProductBuys(this.props.id)
        }
    }

    constructor(props) {
        super(props)

        this.state = {
            showFilters: false,
            currentForm: 'sale',
            filters: {
                ...defaultFiltersValue
            }
        }
    }

    addToCartSaleItem = (productId, qtyToAdd) => {
        this.props.addToCart(productId, qtyToAdd, this.props.intl)
            .then(() => this.props.fetchProductSales(this.props.id))
    }

    addToCartBuyItem = (productId, qtyToAdd) => {
        this.props.addToCart(productId, qtyToAdd, this.props.intl)
            .then(() => this.props.fetchProductBuys(this.props.id))
    }

    canManageProduct() {
        if (
            (this.props.product.productCategory === 'mtg_sealed' ||
                this.props.product.productCategory === 'product_accessory' ||
                this.props.product.productCategory === 'pokemon_sealed') &&
            this.props.isLogged
        ) {
            return this.props.isBusiness
        }
        return true
    }

    renderForms = () => {
        const routeId = this.props.intl.formatMessage({ id: 'ROUTE_USER_PROFILE' }).replace('/:view?', '#shipping-information')
        return (
            <React.Fragment>
                <div className="row no-gutters" style={{ marginRight: '-2px' }}>
                    <div className="btn-group">
                        <span
                            className={`btn btn-primary ${this.state.currentForm === 'sale' ? 'active' : ''}`}
                            onClick={() => this.setState({ currentForm: 'sale' })}
                            aria-current="page"
                        >
                            Add to sale
                        </span>
                        <span className={`btn btn-primary ${this.state.currentForm === 'buy' ? 'active' : ''}`} onClick={() => this.setState({ currentForm: 'buy' })}>
                            Add to buy
                        </span>
                        <span className={`btn btn-primary ${this.state.currentForm === 'collection' ? 'active' : ''}`} onClick={() => this.setState({ currentForm: 'collection' })}>
                            Add to collection
                        </span>
                    </div>
                </div>

                <div className="container mb-4 mt-4" style={{ paddingRight: '32px' }}>
                    {!this.props.isLogged && <LoginInLineContainer intl={this.props.intl} />}

                    {this.props.isLogged && (
                        <React.Fragment>
                            {!this.props.verified && <Alerts alert={'VERIFY_YOUR_EMAIL'} status={'warning'} />}
                            {this.props.verified && this.state.currentForm !== 'collection' && (!this.props.bankData || !this.props.bankData.validated) && (
                                <Alerts alert={'FILL_FINANCIAL_INFO_TO_TRADE'} status={'warning'} />
                            )}

                            {this.props.verified &&
                                this.props.hasFilledShippingAddress &&
                                this.props.bankData &&
                                this.props.bankData.validated &&
                                this.state.currentForm === 'sale' && <AddToSaleForm onlyForm itemOnProductCard={this.props.product} />}
                            {this.props.verified &&
                                this.props.hasFilledShippingAddress &&
                                this.props.bankData &&
                                this.props.bankData.validated &&
                                this.state.currentForm === 'buy' && <AddToBuyForm onlyForm itemOnProductCard={this.props.product} />}
                            {this.props.verified && this.state.currentForm === 'collection' && <AddToCollectionForm onlyForm itemOnProductCard={this.props.product} />}
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        )
    }
    renderFilters = () => {
        return (
            <form className='card-body' onSubmit={(event) => this.sendFilters(event)}>
                <div className='row'>
                    <div className='col-12 col-lg-3 col-xl-2'>
                        <label htmlFor='select_one' className='form-label'>
                            <Translation id='select_one' defaultMessage='Select one' />
                        </label>
                        <Select
                            className=''
                            options={[
                                {
                                    label: 'Showing sellers',
                                    value: 'sale'
                                },
                                { label: 'Showing buyers', value: 'buy' }
                            ]}
                            value={this.state.filters.selectedItemType}
                            onChange={(selectedOption) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        selectedItemType: selectedOption
                                    }
                                })
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select'
                                }) + '...'
                            }
                        />
                    </div>

                    <div className='col-12 col-lg-3 col-xl-1'>
                        <label htmlFor='qty' className='form-label'>
                            <Translation id='language' defaultMessage='Language' />
                        </label>
                        <Select
                            className=''
                            options={this.props.availableLangsIncludingAny}
                            value={this.state.filters.selectedLang}
                            onChange={(selectedOption) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        selectedLang: selectedOption
                                    }
                                })
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select'
                                }) + '...'
                            }
                        />
                    </div>
                    <div className='col-12 col-lg-3 col-xl-2'>
                        <label htmlFor='qty' className='form-label'>
                            <Translation id='condition' defaultMessage='Condition' />
                        </label>
                        <Select
                            className=''
                            options={this.props.availableStatus}
                            value={this.state.filters.selectedCondition}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            selectedCondition: selectedOption
                                        }
                                    },
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select'
                                }) + '...'
                            }
                        />
                    </div>

                    <div className='col-12 col-lg-3 col-xl-2'>
                        <div className='d-flex flex-row'>
                            <div>
                                <label className='form-label'>Price Min</label>
                                <input
                                    type='number'
                                    value={this.state.filters.priceMin}
                                    min={0.0}
                                    step={0.01}
                                    className='form-control'
                                    id='minPrice'
                                    onChange={(event) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                priceMin: event.target.value
                                            }
                                        })
                                    }}
                                />
                            </div>
                            <div>
                                <label className='form-label'>Price Max</label>
                                <input
                                    type='number'
                                    value={this.state.filters.priceMax}
                                    min={0.0}
                                    step={0.01}
                                    className='form-control'
                                    id='maxPrice'
                                    onChange={(event) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                priceMax: event.target.value
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-2 col-xl-1'>
                        <label htmlFor='foil' className='form-label'>
                            <Translation id='foil' defaultMessage='Foil' />
                        </label>
                        <Select
                            className=''
                            options={this.booleanOptions}
                            value={this.state.filters.isFoil}
                            onChange={(selectedOption) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        isFoil: selectedOption
                                    }
                                })
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select'
                                }) + '...'
                            }
                        />
                    </div>
                    <div className='col-12 col-lg-2 col-xl-1'>
                        <label htmlFor='signed' className='form-label'>
                            <Translation id='signed' defaultMessage='Signed' />
                        </label>
                        <Select
                            className=''
                            options={this.booleanOptions}
                            value={this.state.filters.isSigned}
                            onChange={(selectedOption) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        isSigned: selectedOption
                                    }
                                })
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select'
                                }) + '...'
                            }
                        />
                    </div>
                    <div className='col-12 col-lg-2 col-xl-1'>
                        <label htmlFor='playset' className='form-label'>
                            <Translation id='playset' defaultMessage='Playset' />
                        </label>
                        <Select
                            className=''
                            options={this.booleanOptions}
                            value={this.state.filters.isPlayset}
                            onChange={(selectedOption) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        isPlayset: selectedOption
                                    }
                                })
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select'
                                }) + '...'
                            }
                        />
                    </div>
                    <div className='col-12 col-lg-2 col-xl-1'>
                        <label htmlFor='isAltered' className='form-label'>
                            <Translation id='altered' defaultMessage='Altered' />
                        </label>
                        <Select
                            className=''
                            options={this.booleanOptions}
                            value={this.state.filters.isAltered}
                            onChange={(selectedOption) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        isAltered: selectedOption
                                    }
                                })
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select'
                                }) + '...'
                            }
                        />
                    </div>
                    <div className='col-12 col-lg-3 col-xl-1'>
                        <label htmlFor='clear' className='form-label'>
                            {/* <Translation id="clear_the_filters" defaultMessage="Empty" /> */}
                            &nbsp;
                        </label>
                        <Button
                            icon='trash'
                            secondary
                            onClick={() =>
                                this.setState({
                                    filters: {
                                        ...defaultFiltersValue
                                    }
                                })
                            }
                        >
                            <Translation id='clear' defaultMessage='Clear' />
                        </Button>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        if (this.props.isLoadingProduct) {
            return <Spinner isLoading={true}></Spinner>
        }

        if (!this.props.product) {
            return null;
        }

        let saleItemsFiltered = this.props.saleItems
        let buyItemsFiltered = this.props.buyItems

        saleItemsFiltered = filterItems(saleItemsFiltered, this.state.filters, this.props.availableStatus)
        buyItemsFiltered = filterItems(buyItemsFiltered, this.state.filters, this.props.availableStatus)

        const productDetailByCategory = {
            mtg_single: <ProductSingleDetails product={this.props.product} intl={this.props.intl} goTo={this.props.push} />,
            mtg_sealed: <ProductSealedDetails product={this.props.product} intl={this.props.intl} goTo={this.props.push} />,
            lorcana_single: <LorcanaProductSingleDetails product={this.props.product} intl={this.props.intl} goTo={this.props.push} />,
            pokemon_single: <PokemonProductSingleDetails product={this.props.product} intl={this.props.intl} goTo={this.props.push} />,
            pokemon_sealed: <PokemonProductSealedDetails product={this.props.product} intl={this.props.intl} goTo={this.props.push} />,
            fab_single: <FabProductSingleDetails product={this.props.product} intl={this.props.intl} goTo={this.props.push} />,
            product_accessory: <ProductAccessoryDetails product={this.props.product} intl={this.props.intl} goto={this.props.push} />,
        }

        return (
            <React.Fragment>
                <div className='container-fluid section invisible-section' id='product-card'>
                    {/* <GoBack></GoBack> */}
                    <div className='row '>
                        <div className='col-12 col-lg-8 mt-2'>
                            <div className='row'>
                                {
                                    productDetailByCategory[this.props.product.productCategory]
                                }
                            </div>
                        </div>
                        {this.canManageProduct() && <div className='d-none d-lg-block col-lg-4 right-side-forms'
                                                         style={{ borderRadius: '16px' }}>
                            {this.renderForms()}
                        </div>}
                    </div>
                </div>
                <div className='container-fluid d-lg-none'
                     style={{ paddingTop: '0', paddingRight: '0', paddingLeft: '12px' }}>
                    {this.canManageProduct() && <div className='row'>
                        <div className='col-12 d-lg-none right-side-forms'>{this.renderForms()}</div>
                    </div>}
                </div>
                <div>
                    <div className='container-fluid'>
                        <div className=''>
                            <h4 className='card-title'>
                                <Translation id='filters' defaultMessage='Filters' />
                            </h4>
                            <div className='mt-3 mb-3'>
                                <div className='d-none d-lg-block'>{this.renderFilters()}</div>
                                <div className='d-lg-none row'>
                                    <div className='col-8'>
                                        <Select
                                            className=''
                                            options={[
                                                {
                                                    label: 'Showing sellers',
                                                    value: 'sale'
                                                },
                                                { label: 'Showing buyers', value: 'buy' }
                                            ]}
                                            value={this.state.filters.selectedItemType}
                                            onChange={(selectedOption) => {
                                                this.setState({
                                                    filters: {
                                                        ...this.state.filters,
                                                        selectedItemType: selectedOption
                                                    }
                                                })
                                            }}
                                            placeholder={
                                                this.props.intl.formatMessage({
                                                    id: 'select'
                                                }) + '...'
                                            }
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <Button onClick={() => this.setState({ showFilters: true })}>
                                            <span className='material-icons-outlined' style={{ fontSize: '32px' }}>
                                                filter_alt
                                            </span>
                                        </Button>
                                    </div>
                                    {this.state.showFilters && (
                                        <Modal title={<Translation id='filters' defaultMessage='Filters' />}
                                               onClose={() => this.setState({ showFilters: false })}>
                                            {this.renderFilters()}
                                        </Modal>
                                    )}
                                </div>
                            </div>
                            <div id='scrollableDivSales' className='card-list'>
                                {this.state.filters.selectedItemType?.value === 'sale' &&
                                    (saleItemsFiltered.length > 0 ? (
                                        saleItemsFiltered.map((original) => (
                                            <ProductSaleItem
                                                key={original.id}
                                                original={original}
                                                intl={this.props.intl}
                                                isLogged={this.props.isLogged}
                                                userName={this.props.userName}
                                                isVerified={this.props.verified}
                                                addingToCart={this.props.addingToCart}
                                                isLoading={this.props.isLoading}
                                                bankData={this.props.bankData}
                                                onShowUpLogin={this.props.onShowUpLogin}
                                                fetchProductSalesCallback={() => this.props.fetchProductSales(this.props.id)}
                                                addToCart={this.addToCartSaleItem}
                                            />
                                        ))
                                    ) : !this.props.isLoading ? (
                                        <div className='row'>
                                            <div className='col-10 offset-1 col-md-4 offset-md-4 my-4'>
                                                <h5 style={{ textAlign: 'center' }}>
                                                    <Translation id='no_results_found'
                                                                 defaultMessage='No results found' />
                                                </h5>
                                            </div>
                                        </div>
                                    ) : null)}

                                {this.state.filters.selectedItemType?.value === 'buy' &&
                                    (buyItemsFiltered.length > 0 ? (
                                        buyItemsFiltered.map((original) => (
                                            <ProductBuyItem
                                                key={original.id}
                                                original={original}
                                                intl={this.props.intl}
                                                isLogged={this.props.isLogged}
                                                userName={this.props.userName}
                                                isVerified={this.props.verified}
                                                addingToCart={this.props.addingToCart}
                                                isLoading={this.props.isLoading}
                                                bankData={this.props.bankData}
                                                onShowUpLogin={this.props.onShowUpLogin}
                                                addToCart={this.addToCartBuyItem}
                                            />
                                        ))
                                    ) : !this.props.isLoading ? (
                                        <div className='row'>
                                            <div className='col-10 offset-1 col-md-4 offset-md-4 my-4'>
                                                <h5 style={{ textAlign: 'center' }}>
                                                    <Translation id='no_results_found'
                                                                 defaultMessage='No results found' />
                                                </h5>
                                            </div>
                                        </div>
                                    ) : null)}
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(Product)
