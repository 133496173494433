import React from 'react'
import Translation from '../global/Translation'
import CartLineContainer from '../cart-line/CartLineContainer'
import DisplayPrice from '../product/DisplayPrice'
import Select from 'react-select'
import { PublicProfileLink } from '../global/PublicProfileLink'
import FlagIcon from '../global/FlagIcon'
import { PickupAddress } from '../address/PickupAddress'

class CartSummary extends React.Component {
    constructor(props) {
        super(props)
        let initState = {}
        this.state = initState
        //    Object.entries(this.props.linesBySeller).map((sellerItems) => {
        //        console.log('setting shippingmethod for seller' + sellerItems[0])
        //        this.props.changeShippingMethodBySeller(sellerItems[0], { value: 'normal', label: 'normal' })
        //    })
    }

    // componentDidMount() {
    //     this.props.fetchShippingMethods()
    // }


    // componentDidUpdate(prevProps) {
    //     if (prevProps.linesBySeller !== this.props.linesBySeller) {
    //         console.log(prevProps.linesBySeller)
    //         console.log(this.props.linesBySeller)
    //         console.log('hellou')
    // Object.entries(this.props.linesBySeller).map((sellerItems) => {
    //     console.log('setting shippingmethod for seller' + sellerItems[0])
    //     this.props.changeShippingMethodBySeller(sellerItems[0], { value: 'normal', label: 'normal' })
    // })
    //     }
    // }
    openChat = (id) => {
        this.props.createChat(this.props.userLoggedId,id)
    }

    userHasNotEnoughBuyCredit = (userId) => {
        const buyersUserIdWithoutCredit = this.props.checkoutError?.errors?.buyersUserId
        if (!buyersUserIdWithoutCredit || ! Array.isArray(buyersUserIdWithoutCredit) ) {
            return false;
        }
        return buyersUserIdWithoutCredit.includes(userId);
    }

    render() {
        const {
            lines,
            totalQty,
            bySeller,
            linesBySeller,
            linesBySellerFromBackend,
            changeShippingMethodBySeller,
            cartType
        } = this.props

        if (totalQty <= 0) {
            return (
                <div className='total cart-empty container-fluid'>
                    <div className='row'>
                        <div className='label'>
                            <Translation id='your_cart_is_empty' defaultMessage='Your cart is empty' />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <React.Fragment>
                {this.props.checkoutError?.errorCode && <h5><Translation id={this.props.checkoutError.errorCode}></Translation></h5>}
                    {bySeller &&
                        Object.entries(linesBySeller).map(([sellerName, sellerLines]) => {
                            let sellerItemsTotalPrice = 0
                            let sellerItemsTotalQty = 0
                            let shippingMethod = linesBySellerFromBackend[sellerName]['shippingMethod']
                            const sellerId = sellerLines[0]['sellerId']
                            const sellerNameSlug = sellerLines[0]['sellerNameSlug']
                            const sellerCountryCode = sellerLines[0]['sellerCountryCode']

                            const isPickup = shippingMethod?.type === 'pickup'
                            const pickupAddress = linesBySellerFromBackend[sellerName]['sellerAddress'];

                            // opciones de envio
                            let shippingOptions = []
                            if(linesBySellerFromBackend[sellerName]['shippingOptions']) {
                                let options = linesBySellerFromBackend[sellerName]['shippingOptions']
                                for (const [key, shippingOption] of Object.entries(options)) {
                                    shippingOptions.push({
                                        value: shippingOption.id,
                                        label: `${shippingOption.name} - ${shippingOption.cost}`
                                    })
                                }
                            }

                            return (
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {cartType === 'SALE' ? (
                                                <Translation id="BUYING_ITEMS_TITLE" defaultMessage="You are buying those items from"></Translation>
                                            ) : (
                                                <Translation id="SELLING_ITEMS_TITLE" defaultMessage="You are selling those items to"></Translation>
                                            )}
                                            &nbsp;
                                            <PublicProfileLink userNameSlug={sellerNameSlug} label={sellerName} /> <FlagIcon code={sellerCountryCode.toLowerCase()} />
                                            &nbsp;
                                            {/* DISABLED CAUSE WITH PAYMENT GATEWAY YOU CAN MAKE ORDER WITHOUTH CREDIT */}
                                            {/* {this.userHasNotEnoughBuyCredit(sellerId) && (
                                                <span style={{ color: 'red' }}>
                                                    <i className="fa fa-exclamation-triangle"></i> <Translation id="NOT_ENOUGH_CREDIT"></Translation>
                                                </span>
                                            )} */}
                                            <small
                                                style={{ cursor: 'pointer', padding: '16px' }}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.openChat(sellerId)
                                                }}
                                            >
                                                <i className="fa fa-paper-plane" /> Contact
                                            </small>
                                            <span
                                                className="bt-delete"
                                                onClick={() => {
                                                    this.props.removeLinesFromSeller(sellerId)
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <i className="fal fa-trash-alt" />
                                            </span>
                                        </h5>
                                        <div>
                                            {sellerLines.map((line) => {
                                                sellerItemsTotalPrice = sellerItemsTotalPrice + line.unitPrice * line.qty
                                                sellerItemsTotalQty = sellerItemsTotalQty + line.qty
                                                return <CartLineContainer line={line} key={line.lineId} showSellerName={false} onlyView={line.extra && line.extra.itsFree} />
                                            })}
                                            <div className="list-group list-group-flush">
                                                {cartType === 'SALE' && (
                                                    <React.Fragment>
                                                        <Select
                                                            className="border-bottom border-secondary"
                                                            // defaultValue={{ value: 'normal', label: 'normal' }}
                                                            options={shippingOptions}
                                                            value={shippingOptions.find((item) => item.value == shippingMethod.id)}
                                                            onChange={(selectedOption) => {
                                                                shippingMethod = selectedOption.value
                                                                changeShippingMethodBySeller(sellerName, selectedOption.value)
                                                            }}
                                                        />
                                                                      {isPickup && pickupAddress && <li className='list-group-item'>
                                                        <PickupAddress address={pickupAddress} showInLine={true} />
                                                    </li>}
                                                        <li className="list-group-item">
                                                            Items cost: <DisplayPrice price={sellerItemsTotalPrice} />
                                                        </li>
                                                        <div className="card-footer">
                                                            Total (with shipping taxes):{' '}
                                                            <DisplayPrice price={linesBySellerFromBackend[sellerName]['totalPriceWithShippingTaxes']} />
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                                {cartType === 'BUY' && (
                                                    <div className="card-footer">
                                                        Total: <DisplayPrice price={linesBySellerFromBackend[sellerName]['totalPriceWithShippingTaxes']} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    {!bySeller && lines.map((line) => line && <CartLineContainer line={line} key={line.lineId}
                                                                                 onlyView={line.extra && line.extra.itsFree} />)}

                <br />
            </React.Fragment>
        )
    }
}

export default CartSummary
