import {
    getCollection,
    getCollectionExpansionsAvailableList,
    getCollectionRarityAvailableList,
    getCollectionTableInfo,
    getIsLoading
} from '../store/collection/reducers'
import React from 'react'
import { connect } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../components/global/Translation'
import { deleteItemFromCollection, fetchCollections, updateQtyItemFromCollection } from '../store/collection/actions'
import Button from '../components/global/Button'
import confirm from '../components/global/confirm'
import LocalizedLink from '../components/global/LocalizedLink'
import {
    getAvailableCollectionsOrderBy,
    getAvailableLangsIncludingAny,
    getAvailableStatusIncludingAny,
    getBooleanOptions
} from '../store/products/reducers'
import { showLoginModal } from '../store/ui/actions'
import { getBankData, getIsLogged, getUserId, getUserName, getVerified } from '../store/login/reducers'
import { RenderCollectionItemMobile } from '../components/collection/list/RenderCollectionItemMobile'
import debounce from 'lodash/debounce'
import InfiniteScroll from 'react-infinite-scroll-component'
import SaleItemsFilters from '../components/sale/items/SaleItemsFilters'
import { parseFiltersToLaravel } from '../components/shared/filters/filters-utils'

defineMessages({
    TOKEN_HAS_EXPIRED: {
        id: 'MY_COLLECTION_LIST',
        description: 'MY_COLLECTION_LIST',
        defaultMessage: 'My collection list'
    },
    graded: {
        id: 'graded',
        description: 'graded',
        defaultMessage: 'graded'
    },
    actions: {
        id: 'actions',
        description: 'actions',
        defaultMessage: 'Actions'
    }
})


const mapStateToProps = (state) => {
    return {
        isLoading: getIsLoading(state),
        collectionItems: getCollection(state),
        collectionTableInfo: getCollectionTableInfo(state),
        availableLangs: getAvailableLangsIncludingAny(state),
        availableStatus: getAvailableStatusIncludingAny(state),
        booleanOptions: getBooleanOptions(state),
        expansionOptions: getCollectionExpansionsAvailableList(state), //getExpansionOptionsIncludingAny(state),
        rarityOptions: getCollectionRarityAvailableList(state),
        availableOrderByOptions: getAvailableCollectionsOrderBy(state),
        bankData: getBankData(state),
        userIdLogged: getUserId(state),
        userName: getUserName(state),
        isLogged: getIsLogged(state),
        verified: getVerified(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCollection: (newPage, pageSize, filters, orderBy) => {
            dispatch(fetchCollections(newPage, pageSize, filters, orderBy))
        },
        deleteItemFromCollection: (itemId) => {
            return dispatch(deleteItemFromCollection(itemId))
        },
        updateQtyItemFromCollection: (itemId, qty) => {
            return dispatch(updateQtyItemFromCollection(itemId, qty))
        },
        onShowUpLogin: () => {
            dispatch(showLoginModal())
        }
    }
}
const confirmDeleteItem = (itemId, item, onDeleteItem, intl) => {
    return confirm(intl.formatMessage({ id: 'CONFIRM_DELETE_ITEM_FROM_COLLECTION' })).then(
        (confirm) => {
            return onDeleteItem(itemId)
        },
        (cancel) => {
            return Promise.reject()
        }
    )
}

const defaultFiltersValue = {
    search: '',
    comments: '',
    selectedExpansion: null,
    selectedLang: null,
    selectedCondition: null,
    selectedRarity: {
        value: null,
        label: 'Any'
    },
    isFoil: {
        value: null,
        label: 'Any'
    },
    isSigned: {
        value: null,
        label: 'Any'
    },
    isPlayset: {
        value: null,
        label: 'Any'
    },
    isAltered: {
        value: null,
        label: 'Any'
    }
}


class CollectionScreen extends React.Component {
    defaultState = {
        filters: { ...defaultFiltersValue },
        orderBy: {
            label: 'Name asc',
            value: {
                column: 'products.name',
                direction: 'asc'
            }
        }
    }

    state = { ...this.defaultState }

    constructor() {
        super()
        this.searchCollections = debounce(this.searchCollections.bind(this), 400)
    }

    componentDidMount() {
        this.props.fetchCollection()
    }

    isUserListEqualToCurrentLogged() {
        //Dont put triple equal on this line
        return this.props.match.params.id ? this.props.match.params.id == this.props.userIdLogged : true
    }

    isUserItemEqualToCurrentLogged(item) {
        return this.props.userIdLogged == item.user_id
    }

    searchCollections(page = 1, pageSize = 10) {
        this.props.fetchCollection(page, pageSize, parseFiltersToLaravel(this.state.filters), this.state.orderBy?.value)
    }

    setFilterValue = (filterKey, value) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [filterKey]: value
            }
        }, () => {
            this.searchCollections(1, 10)
        })
    }

    setOrderByValue = (orderBy) => {
        this.setState({
                orderBy
            }, () => this.searchCollections(1, 10)
        )
    }

    resetFiltersValue(callback = null) {
        this.setState(
            {
                ...this.state,
                filters: {
                    ...defaultFiltersValue
                }
            },
            () => {
                if (callback) {
                    callback()
                }
            }
        )
    }

    render() {
        let collectionItemsFiltered = this.props.collectionItems ?? []

        return (
            <div>
                <div className='container-fluid'>
                    <div className='row' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className='col-12 col-md-4'>
                            <h2>
                                <Translation id='MY_COLLECTION_LIST' />
                            </h2>
                        </div>
                        <div className='col-12 col-md-3 col-lg-2'>
                            <LocalizedLink routeId={'ROUTE_USER_DASHBOARD'}
                                           params={{ view: 'add-to-collection', id: '' }}>
                                <Button icon='plus-circle'>
                                    <Translation id='NEW_ITEM' defaultMessage='New item' />
                                </Button>
                            </LocalizedLink>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <h4 className='card-title'>
                        <Translation id='filters' defaultMessage='Filters' />
                    </h4>
                    <div className='mt-3 mb-3'>
                        <SaleItemsFilters
                            filters={this.state.filters}
                            orderBy={this.state.orderBy}
                            expansionOptions={this.props.expansionOptions}
                            rarityOptions={this.props.rarityOptions}
                            productsCategoriesOptions={this.props.productsCategoriesOptions}
                            availableLangs={this.props.availableLangs}
                            availableStatus={this.props.availableStatus}
                            booleanOptions={this.props.booleanOptions}
                            availableOrderByOptions={this.props.availableOrderByOptions}
                            shouldDisplayProductCategoryFilter={false}
                            setFilters={this.setFilterValue}
                            setOrderBy={this.setOrderByValue}
                            onSubmit={this.searchCollections}
                            onReset={() => this.resetFiltersValue(() => this.searchCollections(1, 10))}
                        />
                    </div>

                    <div className='card-list'>
                        <InfiniteScroll
                            dataLength={collectionItemsFiltered.length}
                            next={() => this.props.collectionTableInfo.page < this.props.collectionTableInfo.pagesCount && this.searchCollections(this.props.collectionTableInfo.page + 1, 10)}
                            hasMore={this.props.collectionTableInfo.page < this.props.collectionTableInfo.pagesCount}
                            loader={<h4>Loading...</h4>}
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>You have seen it all</b>
                                </p>
                            }
                        >
                            {collectionItemsFiltered.map((item) => {
                                return (
                                    <RenderCollectionItemMobile
                                        key={item.id}
                                        item={item}
                                        intl={this.props.intl}
                                        onShowUpLogin={this.props.onShowUpLogin}
                                        isLogged={this.props.isLogged}
                                        userName={this.props.userName}
                                        bankData={this.props.bankData}
                                        isVerified={this.props.verified}
                                        isUserItemEqualToCurrentLogged={this.isUserListEqualToCurrentLogged(item)}
                                        updateQty={(id, qty) => {
                                            this.props.updateQtyItemFromCollection(id, qty).then(() => this.searchCollections())
                                        }}
                                        deleteCollectionItem={(id, original) => confirmDeleteItem(id, original, this.props.deleteItemFromCollection, this.props.intl).then(() => this.searchCollections())}
                                    />
                                )
                            })}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(CollectionScreen)))
