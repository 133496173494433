import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter,Route,Switch } from 'react-router-dom'
import AddToCollectionForm from '../components/collection/AddToCollectionForm'

const AddToCollectionScreen = (props,intl) => {
    return (
        <div className="container-fluid">
            <AddToCollectionForm goBackAfterCreate={true}></AddToCollectionForm>
        </div>
    )
}

export default withRouter(injectIntl(connect()(AddToCollectionScreen)))
