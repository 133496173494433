export const parseFiltersToLaravel = (filters) => {
    let filtersRes = {}

    Object.entries(filters).forEach(([key, value]) => {
        if (value) {
            if (typeof value === 'object' && !Array.isArray(value)) {
                value = value.value
            }
            filtersRes[key] = value
        }
    })
    return filtersRes
}
