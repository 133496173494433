import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

const SelectFilter = ({ name, intl, label, value, selectOptions, updateFilterValue, ...restInputOptions }) => {
    return (
        <div className='w-100'>
            <label htmlFor={name} className='form-label'>
                {label}
            </label>
            <Select
                options={selectOptions}
                value={value}
                onChange={(selectedOption) => updateFilterValue(name, selectedOption)}
                placeholder={
                    intl.formatMessage({
                        id: 'select'
                    }) + '...'
                }
            />
        </div>
    )
}

const NumberFilter = ({ label, name, value, updateFilterValue, ...restOptions }) => {
    return (
        <div className='w-100'>
            <label htmlFor={name} className='form-label'>{label}</label>
            <input
                type='number'
                value={value}
                className='form-control'
                id={name}
                name={name}
                onChange={(event) => updateFilterValue(name, event.target.value)}
                {...restOptions}
            />
        </div>
    )
}

const TextFilter = ({ label, name, value, updateFilterValue, ...restOptions }) => {
    return (
        <div className='w-100'>
            <label htmlFor={name} className='form-label'>
                {label}
            </label>
            <input
                type='text'
                className='form-control'
                id={name}
                name={name}
                value={value}
                autoComplete='off'
                onChange={(e) => updateFilterValue(name, e.target.value)}
                {...restOptions}
            />
        </div>
    )
}

const Filter = ({ type, ...props }) => {
    const filterComponents = {
        text: TextFilter,
        number: NumberFilter,
        select: SelectFilter
    }

    const FilterComponent = filterComponents[type]
    return FilterComponent ? <FilterComponent {...props} /> : null
}

Filter.propTypes = {
    type: PropTypes.oneOf(['text', 'number', 'select']).isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    updateFilterValue: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired,
    selectOptions: PropTypes.array // Only for select type
}

export default injectIntl(Filter)
